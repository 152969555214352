html,
body {
  font-family: sans-serif;
  background-color: #f5f6fe;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  padding: 1em;

  .subject {
    width: 100%;
  }

  h2 {
    margin: 1em;
    color: #1a0d53;
    font-weight: 400;
    font-size: clamp(2em, 5vw, 3em);
  }

  .quill {
    background-color: white;

    .ql-toolbar.ql-snow {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      & + .ql-container.ql-snow {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .ql-editor {
          min-height: 10em;
        }
      }
    }
  }
  .email-list {
    text-align: left;
  }
}

.ant-modal-content {
  border-radius: 5px;

  .ant-modal-footer {
    display: none;
  }
}

.modal {
  background-color: white;
  height: min(80%, 20em);
  width: min(80%, 20em);
  justify-self: center;

  .status-false,
  .status-true {
    color: #555;
  }
  .status-false::before,
  .status-true::before {
    content: "✓";
    background-color: green;
    color: white;
    border-radius: 50%;
    width: 1.4em;
    height: 1.4em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.2);
    margin: 1em;
  }
  .status-false::before {
    content: "✘";
    background-color: red;
  }
}

.ant-message {
  text-align: left;
}
